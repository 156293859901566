// extracted by mini-css-extract-plugin
export var googleWorkspaceWrapper = "workspace-module--googleWorkspaceWrapper--qCLe3";
export var flexContainer = "workspace-module--flexContainer--qcrH7";
export var googleWorkspaceContainer = "workspace-module--googleWorkspaceContainer--cYOTJ";
export var bookingButton = "workspace-module--bookingButton--9tpig";
export var altBookingButton = "workspace-module--altBookingButton--rVVnL";
export var spacer = "workspace-module--spacer--GSE43";
export var altSpacer = "workspace-module--altSpacer--Bs6rp";
export var whatsappButton = "workspace-module--whatsappButton--fTPED";
export var altWhatsappButton = "workspace-module--altWhatsappButton--SCgR2";
export var headingWrapper = "workspace-module--headingWrapper--DNFWl";
export var buttonWrapper = "workspace-module--buttonWrapper--jxJHa";
export var buttonWrapperFlexColumn = "workspace-module--buttonWrapperFlexColumn--LS7NT";
export var googlePartnerBadge = "workspace-module--googlePartnerBadge--uLskJ";
export var headingContainer = "workspace-module--headingContainer--q0M+j";
export var whatWorkspaceCanDoSection = "workspace-module--whatWorkspaceCanDoSection--XV89b";
export var workspaceSectionColumn = "workspace-module--workspaceSectionColumn--HSXYP";
export var workspaceQuestionsSection = "workspace-module--workspaceQuestionsSection--foiE6";
export var workspaceQuestionsColumns = "workspace-module--workspaceQuestionsColumns--zFoP5";
export var workspaceQuestionsColumnsHeader = "workspace-module--workspaceQuestionsColumnsHeader--u8rBc";
export var workspaceQuestionsColumnsHeaderTwo = "workspace-module--workspaceQuestionsColumnsHeaderTwo--2a-t4";
export var customerTestimonialColumns = "workspace-module--customerTestimonialColumns--H+gtf";
export var planStyle = "workspace-module--planStyle--GTOko";
export var planListContainer = "workspace-module--planListContainer--7DnSN";
export var planStyleContainer = "workspace-module--planStyleContainer--L8ruM";
export var planTitleFlexContainer = "workspace-module--planTitleFlexContainer--jAKs5";
export var planPriceStyles = "workspace-module--planPriceStyles--1ZGUT";
export var planSection = "workspace-module--planSection--O+kg3";
export var planColumnSection = "workspace-module--planColumnSection--mLmUg";
export var planTitleStyles = "workspace-module--planTitleStyles--bxtwv";
export var planHeaderStyles = "workspace-module--planHeaderStyles--Ahgch";
export var faqSectionHeader = "workspace-module--faqSectionHeader--t2Ndq";
export var calendarTitleStyle = "workspace-module--calendarTitleStyle--ytK6W";
export var calendarSubtitleStyles = "workspace-module--calendarSubtitleStyles--Nt4EF";
export var planSpacer = "workspace-module--planSpacer--es3Rh";
export var calendarWrapper = "workspace-module--calendarWrapper--aK4ql";
export var planIcons = "workspace-module--planIcons--KRgXM";
export var fittingFlexContainer = "workspace-module--fittingFlexContainer--ejVo0";
export var fittingFlexContainerInner = "workspace-module--fittingFlexContainerInner--eRvP6";
export var successListContainer = "workspace-module--successListContainer--JttHH";
export var listContainer = "workspace-module--listContainer--hagEB";
export var customerSuccessWrapper = "workspace-module--customerSuccessWrapper--SnJxo";
export var planWrapper = "workspace-module--planWrapper--J2TSo";
export var customerSuccessHeader = "workspace-module--customerSuccessHeader--d3bbC";
export var customerSuccessContainer = "workspace-module--customerSuccessContainer--TR8DG";
export var successStoryDesc = "workspace-module--successStoryDesc--JKBdT";
export var paddedContainer = "workspace-module--paddedContainer--9IA6N";
export var customerSuccessButtonWrapper = "workspace-module--customerSuccessButtonWrapper--NuMb8";
export var socialIconContainer = "workspace-module--socialIconContainer--SeVy1";
export var workspaceSocialLinkContainer = "workspace-module--workspaceSocialLinkContainer--jN6Sb";
export var bookASlotWrapper = "workspace-module--bookASlotWrapper--FaGhm";
export var bookASlotBodyContainer = "workspace-module--bookASlotBodyContainer--weXXW";
export var bookASlotContent = "workspace-module--bookASlotContent--LfzTp";
export var bookASlotSectionSubtitle = "workspace-module--bookASlotSectionSubtitle---GwPV";
export var bookASlotSection = "workspace-module--bookASlotSection--n+U8w";
export var bookASlotCta = "workspace-module--bookASlotCta--oLL-G";
export var bookASlotCtaInner = "workspace-module--bookASlotCtaInner--Nbx5c";
export var bookASlotPlanSection = "workspace-module--bookASlotPlanSection--HbGGZ";
export var bookASlotPlanSectionInner = "workspace-module--bookASlotPlanSectionInner--+RU41";
export var customerSuccessStoriesContainer = "workspace-module--customerSuccessStoriesContainer--qRf2U";
export var customerSuccessImageWrapper = "workspace-module--customerSuccessImageWrapper--H1qGY";
export var customerSuccessButton = "workspace-module--customerSuccessButton--sxkPD";
export var customerSuccessDivDesktop = "workspace-module--customerSuccessDivDesktop--Jlf55";
export var customerSuccessDivMobile = "workspace-module--customerSuccessDivMobile--axGuR";
export var testimonialSection = "workspace-module--testimonialSection--qlmaO";
export var customerCarousel = "workspace-module--customerCarousel--yVREt";
export var testimonialBlock = "workspace-module--testimonialBlock--NUQf1";
export var carouselBlock = "workspace-module--carouselBlock--ZDrbt";
export var testimonialIndex = "workspace-module--testimonialIndex--j7XSh";
export var sectionWrapper = "workspace-module--sectionWrapper--kxoRN";
export var regionsWrapper = "workspace-module--regionsWrapper--bMXnp";
export var regionsContainer = "workspace-module--regionsContainer--PcvBI";
export var regionsImageWrapper = "workspace-module--regionsImageWrapper--XrCwh";
export var contentWrapper = "workspace-module--contentWrapper--ag-Gb";
export var regionListContainer = "workspace-module--regionListContainer--ZTuvh";
export var whyDeimosFlexContainer = "workspace-module--whyDeimosFlexContainer--O74od";
export var accordionSection = "workspace-module--accordionSection--QOrnK";
export var contentDescriptionStyle = "workspace-module--contentDescriptionStyle--fjHGm";